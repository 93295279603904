"use client";
import React, { useState } from 'react';
import { HoverCard } from '@radix-ui/themes';
import Link from 'next/link';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import NavSubContent from './NavSubContent';

const NavData = ({ _id, title, href, subLinks }) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const handleMobileToggle = (e) => {
    if (subLinks) {
      e.preventDefault();
      setIsMobileOpen(!isMobileOpen);
    }
  };

  const closeMenu = () => {
    setIsMobileOpen(false);
    document.body.click(); // Simulate a click to close the HoverCard on large screens
  };

  return (
    <>
      {/* Mobile Menu Item */}
      <div
        className="lg:hidden flex items-center justify-between px-3 py-2 cursor-pointer"
        onClick={handleMobileToggle}
      >
        <span className="text-base text-gray-900">
          {subLinks ? title : <Link href={href}>{title}</Link>}
        </span>
        {subLinks && (
          <span className="ml-2 text-gray-900">
            {isMobileOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </span>
        )}
      </div>

      {/* Mobile Submenu */}
      {isMobileOpen && subLinks && (
        <div className="lg:hidden pl-6">
          <NavSubContent subLinks={subLinks} closeMenu={closeMenu} />
        </div>
      )}

      {/* Desktop Menu Item */}
      {subLinks ? (
        <div className="hidden lg:block">
          <HoverCard.Root openDelay={0} closeDelay={0}>
            <HoverCard.Trigger asChild>
              <div className="relative inline-block -mx-3 -my-2 rounded-lg px-3 py-2 text-base text-gray-900 transition-colors hover:text-blue-600 cursor-pointer flex items-center justify-between group">
                <span className="relative z-10 flex items-center">
                  {title}
                  <span className="ml-2">
                    <IoIosArrowDown />
                  </span>
                </span>
                {/* Blue Line on Hover */}
                <div className="absolute bottom-0 left-0 h-1 w-full bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></div>
              </div>
            </HoverCard.Trigger>
            <HoverCard.Content
              className="bg-white p-4 rounded shadow-lg"
              sideOffset={5}
              align="start"
              onCloseAutoFocus={(e) => e.preventDefault()} // Prevent focus on close to avoid menu reopening
            >
              <NavSubContent subLinks={subLinks} closeMenu={closeMenu} />
            </HoverCard.Content>
          </HoverCard.Root>
        </div>
      ) : (
        <div className="hidden lg:block">
          <Link
            href={href}
            className="relative inline-block -mx-3 -my-2 rounded-lg px-3 py-2 text-base text-gray-900 transition-colors hover:text-blue-600 group"
            onClick={closeMenu} // Close the menu when the item is clicked
          >
            {title}
            {/* Blue Line on Hover */}
            <div className="absolute bottom-0 left-0 h-1 w-full bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></div>
          </Link>
        </div>
      )}
    </>
  );
};

export default NavData;
