import { navData } from "./NavConstants";
import NavData from "./NavData";

const NavBase = () => {
  return (
    <>
      {navData.map(({ _id, title, href, subLinks }) => (
        <NavData
          key={_id}
          _id={_id}
          title={title}
          href={href}
          subLinks={subLinks}
        />
      ))}
    </>
  );
};

export default NavBase;
