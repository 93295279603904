"use client";
import { useEffect } from 'react';
import Script from 'next/script';

const GoogleTag = ({ gt_id }) => {
    useEffect(() => {
        // Ensure this runs only on the client-side
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
        }
    }, []);

    return (
        <>
            {/* Load the Google Analytics script */}
            <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gt_id}`}></Script>
            <Script strategy="afterInteractive" id="google-analytics">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gt_id}');
                `}
            </Script>
        </>
    );
};

export default GoogleTag;
