// components/ScrollToTop.jsx
"use client";
import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
    const [showTopButton, setShowTopButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setShowTopButton(window.scrollY > 300); // Show button when scrolled 300px down
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {showTopButton && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-4 right-4 p-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 z-50"
                    aria-label="Scroll to top"
                >
                    ↑ Top
                </button>
            )}
        </>
    );
};

export default ScrollToTop;
