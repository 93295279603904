import clsx from "clsx";

const Container = ({ className, ...props }) => {
  return (
    <div
      className={clsx(
        "w-full mx-auto px-4", // base styles: full width, horizontal padding
        "md:max-w-screen-md md:px-10", // md screens: max width for medium screens
        "lg:max-w-screen-lg lg:px-20", // lg screens: max width for large screens, more padding
        "xl:max-w-screen-xl xl:px-20", // xl screens: max width for extra large screens
        "2xl:max-w-screen-2xl xl:px-20", // 2xl screens: max width for 2xl screens
        className // additional custom classes
      )}
      {...props}
    />
  );
};

export default Container;
