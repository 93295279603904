export const reviews = [
  {
    title: "I can easily schedule posts on all channels",
    body: "Postly is the best social media managing software. I have tried a few other social media scheduling apps, but this one is by far the easiest to use. I was able to schedule all my posts in less than an hour, and it took me 5 minutes to figure out how to do that.",
    author: " Ishan Avasthi",
    rating: 5,
  },
  {
    title: "I own a marketing agency and getting…",
    body: "I own a marketing agency and getting post approval to a good spot without having to pay all kinds of extra fees has been the biggest pain for us. After talking through our needs with my team, I went searching and tried dozens of tools that just didn't do what we needed or were so pricey that it stole all our profits. Postly's double approval makes it easy for my team to send over content and for another team member to approve before the customer ever sees it. It makes my job much easier and it gives us all piece of mind that branding and messaging will always be top notch with this tool. Thank you Postly!",
    author: "Kabrina Budwell",
    rating: 5,
  },
  {
    title: "I absolutely love Postly",
    body: "I absolutely love postly. It is the only social media scheduling website that I use and it's the best one out there. There are so many features that make my job so much easier, it makes my social media posts perfect every time. The fact that this site has a free plan too , gives me enough time to test everything for myself and see if I like the program or not before committing to buying a subscription which is very reasonable in price too!",
    author: "Murphy Miller",
    rating: 5,
  },
  {
    title: "This is the best platform ever",
    body: "This is the best platform ever! And Paul is amazing! The customer service I get every time is amazing! I know this platform will become the best platform to manage your social media! Thank you for your hard work Team Postly!",
    author: " Elena Diaz, Systemizit Limited",
    rating: 5,
  },
  {
    title: "100% Positive",
    body: "Hi There, My name is Bud Chapplain and my company is The Self Employed Business Academy. Recently we started our Social Media Campaign and I was introduced to Postly. I am happy to say that the experience as been 100% positive. Easy to use and the platforms were just what we wanted. Keep up the good work and congrats with the new concepts.",
    author: " Bud Chapplain",
    rating: 5,
  },  
  {
    title: "Ich finde es super",
    body: "Ich finde es super, es ist einfach zu bedienen und funktioniert richtig gut. Ich habe es noch nicht bereut, mir dieses Tool zu leisten.",
    author: "Andrea Forsthuber Lifeboxx",
    rating: 5,
  },
  {
    title: "Postly is a Time Saver",
    body: "I am extremely pleased with this software. This is a social media marketing tool that helps you track all your social media in one place. You can easily post updates across multiple social media accounts, which really saves a lot of time updating blog posts for each platform individually. I really like the simplicity of the configuration and the ease of use of the software. No more accessing multiple pages. Easy access from one Dashboard. If you're looking for an easy and affordable way to manage your social media accounts, then do yourself a favour and don’t look further.",
    author: "Edwin Giliams",
    rating: 5,
  },
  {
    title: "I have been using Postly for around 2…",
    body: "I have been using Postly for around 2 weeks now, the platform is really easy to navigate and use, the AI writer feature makes life so much easier especially when having to write alot of captions for different clients, it generates copy that is actually usable, and if the content isn't 100%, you will definitely be able to bounce off it with ideas to write your own. Highly recommend for social media marketers looking for a reliable and easy software to use.",
    author: " Social Feast",
    rating: 5,
  },
  {
    title: "Postly is Awesome! Highly Recommend!",
    body: "Postly has made managing not only our own social media accounts, but also our client accounts so much easier. Linking accounts took seconds. Creating a post with their AI copy writer is a snap. I gave it 1 line of text and it generated three professional paragraphs that perfectly matched the tone we were looking for. Instagram has always been a difficult platform for many tools like postly to post to. Postly however is awesome! No issues, no extra steps, simply upload and post. I highly recommend this product!",
    author: "Charlie",
    rating: 5,
  },
  {
    title: "Excelente herramienta para la creación…",
    body: "Excelente herramienta para la creación de contenido social. recomendado!!! atte Roger Bolivar",
    author: "rogerfxbolivar",
    rating: 5,
  },
  {
    title: "Clean Simple Social Media Posting",
    body: "Postly has a great interface that makes social media management simple and clean. It's easy to use, works great, and has all of the popular platforms.",
    author: "Josiah",
    rating: 5,
  },
  {
    title: "Best Performer for the cost",
    body: "Firstly, the pricing vs what you get is unbeatable in my opinion. It is quite easy to understand the interface and it has a very small learning curve. The unlimited team member feature helped us migrate all our Social media planning, scheduling and posting efforts to Postly, while saving hundreds of hours per year. Posting to multiple social media accounts might not be a big deal when you are sole manager and have a low post frequency. However, the chaos starts when you distribute this posting task to other employees. Even tracking who has social media posting access to which platform was a big issue even in our team of 4 people. It is saving us a lot of time and headaches. We have tried many tools for the job. Postly has the best price for the features you get.",
    author: "can ogun",
    rating: 5,
  },
  {
    title: "Best App For Social Media Management",
    body: "I was using a social media management app which had very restrictions. like cant post videos, cant reschedule posts etc. Then in a facebook group someone suggested me to try postly! i first sign up as a free account to try the app. To my delight the user interface is so far the best i have seen! very easy to use even if you are a first time user you can directly start within 5 minutes! no steep learning curve. If you are looking for an app to manage multiple social media accounts then just go for postly! this probably the best app as of now!",
    author: "Kashyap Rawat",
    rating: 5,
  },
  {
    title: "Master of All!t",
    body: "I have been looking for a social media automation tool for a long time, and finally, I think Postly is the one. At this price point, maintaining all my social media platforms should be hassle-free. Almost all the major social media platforms are included, 9 to be exact, and hopefully, more are on the way. Lastly, I was not looking for an AI writer but including that made it more lucrative. Hopefully, I will enjoy working with it, and Postly will continue to improve its service.",
    author: "MMZ",
    rating: 5,
  },
];
