import Link from "next/link";
import clsx from "clsx";
import Image from "next/image";
import PostlyDark from "@/assets/svgs/PostlyDark.svg";
import PostlyLight from "@/assets/svgs/PostlyLight.svg";

const Logo = ({ className, theme = 'dark', ...props }) => {
  const logoSrc = theme === 'light' ? PostlyLight : PostlyDark;
  return (
    <Link href={"/"}>
      <h2
        className={clsx(
          "text-3xl font-bold text-gray-600 hover:text-gray-900 duration-300",
          className
        )}
        {...props}
      >
        <Image className="w-36"  src={logoSrc} alt="Postly Logo" /> 
      </h2>
    </Link>
  );
};

export default Logo;
