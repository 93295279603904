"use client";
import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import SiteMetaData from './SiteMetaData';
import PostlyAd from "@/assets/svgs/PostlyAd.png";

const PopUpBanner = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('popupShown')) {
      return;
    }

    const timer = setTimeout(() => {
      setShowPopup(true);
      sessionStorage.setItem('popupShown', 'true');
    }, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    sessionStorage.setItem('popupShown', 'true');
  };

  return (
    showPopup && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 p-4">
        <div className="bg-postlyLightGreen p-6 rounded-lg shadow-lg w-full max-w-md md:max-w-2xl flex flex-col items-center">
          {/* Image */}
          <div className="w-full mb-4">
            <Image
              src={PostlyAd}
              alt="Popup Image"
              className="rounded-lg object-cover w-full h-auto"
            />
          </div>

          {/* Buttons */}
          <div className="flex flex-col md:flex-row justify-center space-y-3 md:space-y-0 md:space-x-4 w-full">
            <Link href={SiteMetaData.dashboard} target="_blank">
              <button className="w-full md:w-auto px-4 py-2 bg-postlyBlue text-white text-md rounded-lg hover:bg-blue-700 transition-colors duration-200 text-center">
                Start free
              </button>
            </Link>
            <button
              onClick={handleClosePopup}
              className="w-full md:w-auto px-4 py-2 bg-blue-200 text-gray-900 text-md rounded-lg hover:bg-postlyRed transition-colors duration-200 text-center"
            >
              Not Now
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PopUpBanner;
