import React from 'react';
import NavSubItem from './NavSubItem';

const NavSubContent = ({ subLinks, closeMenu }) => {
  return (
    <section className="flex flex-col gap-2">
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
        {subLinks?.map((item, index) => (
          <NavSubItem key={index} item={item} closeMenu={closeMenu} />
        ))}
      </div>
    </section>
  );
};

export default NavSubContent;
