"use client";
import React, { useEffect, useState } from "react";
import Container from "./Container";
import Logo from "./Logo";
import Button from "./Button";
import { Popover } from "@headlessui/react";
import { TbMenu2 } from "react-icons/tb";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { navData } from "./NavMenu/NavConstants";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import SiteMetaData from "@/components/SiteMetaData";
import NavBase from "./NavMenu/NavBase";
import GetStartedNow from "./GetStartedNow";

const MobileNavLink = ({ children, href, subLinks, onClick, ...props }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const toggleSubmenu = (e) => {
    e?.preventDefault();
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const handleLinkClick = () => {
    setIsSubmenuOpen(false);
    onClick(); // Close the entire menu
  };

  return (
    <>
      <Popover.Button
        as={Link}
        href={href}
        aria-expanded={isSubmenuOpen}
        aria-controls={subLinks ? `submenu-${href}` : undefined}
        className="block text-base leading-7 tracking-tight text-gray-700 font-poppins font-normal"
        onClick={subLinks ? toggleSubmenu : handleLinkClick}
        {...props}
      >
        {children}
        {subLinks && (
          <span className="ml-2" aria-hidden="true">
            {isSubmenuOpen ? (
              <IoIosArrowUp className="inline-block" />
            ) : (
              <IoIosArrowDown className="inline-block" />
            )}
          </span>
        )}
      </Popover.Button>
      {subLinks && isSubmenuOpen && (
        <div
          id={`submenu-${href}`}
          className="ml-4 space-y-2"
          role="menu"
          aria-label={`${children} submenu`}
        >
          {subLinks.map(({ _id, title, href }) => (
            <Link
              key={_id}
              href={href}
              className="block text-sm leading-7 tracking-tight text-gray-600 font-poppins font-normal"
              onClick={handleLinkClick}
            >
              {title}
            </Link>
          ))}
        </div>
      )}
    </>
  );
};

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsScrolled(scrollY > 50);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <header
      className={`w-full sticky top-0 z-50 transition-colors duration-300 ease-in-out ${
        isScrolled ? "bg-white shadow-md" : "bg-transparent"
      }`}
      style={{ zIndex: 1000 }}
      role="banner"
    >
      <nav>
        <Container className="relative z-50 flex justify-between py-4">
          {/* Logo */}
          <div className="relative z-50 flex items-center gap-16">
            <Logo theme={isScrolled ? "dark" : "dark"} />
          </div>
          {/* NavLinks */}
          <div
            className="hidden lg:flex lg:gap-10 items-center font-poppins font-normal"
            role="navigation"
            aria-label="Main navigation"
          >
            <NavBase />
          </div>
          {/* Buttons */}
          <div className="flex items-center gap-6 font-poppins font-normal">
            <Button
              href={SiteMetaData.dashboard}
              variant="outline"
              className="text-lg font-header font-semibold text-postlyBlue bg-gray-50 rounded-full hidden lg:block"
            >
              Sign in
            </Button>
            <div className="hidden lg:block">
              <GetStartedNow theme="light"/>
            </div>
            {/* Mobile NavLinks */}
            <Popover className="lg:hidden">
              {({ open, close }) => (
                <>
                  <Popover.Button
                    aria-expanded={open}
                    aria-label="Toggle mobile navigation"
                    className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-blue-900 p-2 hover:bg-gray-200/50 hover:stroke-blue-600 active:stroke-blue-900 [&:not(:focus-visible)]:focus:outline-none outline-none font-poppins font-normal"
                  >
                    {open ? (
                      <IoIosArrowUp className="text-2xl" />
                    ) : (
                      <TbMenu2 className="text-2xl" />
                    )}
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                          onClick={close}
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20 font-poppins font-normal"
                          role="menu"
                          aria-label="Mobile navigation"
                        >
                          <div className="space-y-4">
                            {navData.map(
                              ({
                                _id,
                                title,
                                href,
                                subLinks,
                                enableOnMobile,
                              }) =>
                                enableOnMobile && (
                                  <MobileNavLink
                                    key={_id}
                                    href={href}
                                    subLinks={subLinks}
                                    onClick={close}
                                  >
                                    {title}
                                  </MobileNavLink>
                                )
                            )}
                          </div>
                          <div className="mt-8 flex flex-col gap-4 rounded-full">
                            <Button
                              href={SiteMetaData.dashboard}
                              variant="outline"
                              onClick={close}
                            >
                              Sign in
                            </Button>
                            <GetStartedNow
                              className="item-center justify-center"
                              onClick={close}
                            />
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default Header;
