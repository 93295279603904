"use client";
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Link from 'next/link';
import SiteMetaData from './SiteMetaData';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookie-consent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookie-consent', 'accepted', { expires: 365 });
    setIsVisible(false);
    // Implement the logic for accepting cookies
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 mx-auto  bg-gray-50 p-4 shadow-md flex flex-col md:flex-row justify-between items-center gap-4 z-50 opacity-100">
      <p className="text-base flex-1 text-center md:text-left">
        This site uses cookies to deliver and enhance the quality of its services and to analyze traffic
      </p>
      <div className="flex flex-wrap gap-2 justify-center md:justify-start">
        <Link href={SiteMetaData.privacyPolicyURL} className="bg-gray-500 text-white px-2 md:px-3 py-1 md:py-2 rounded text-xs md:text-sm">Learn more</Link>
        <button onClick={handleAccept} className="bg-postlyBlue text-white px-2 md:px-3 py-1 md:py-2 rounded text-xs md:text-sm">
          Ok, got it!
        </button>
      </div>
    </div>

  );
};

export default CookieConsent;
