
"use client";
import Script from 'next/script';
import { useEffect } from 'react';

const GoogleAnalytics = ({ ga_id }) => {
  useEffect(() => {
    // Ensure this runs only on the client-side
    if (typeof window !== 'undefined') {
      if (!window.dataLayer) {
        console.error("dataLayer is not defined before initialization.");
        // Initialize dataLayer to prevent errors
        window.dataLayer = window.dataLayer || [];
      }
    }
  }, []);

  return (
    <>
      {/* Load the Google Analytics script */}
      <Script 
        src={`https://www.googletagmanager.com/gtag/js?id=${ga_id}`} 
        strategy="afterInteractive"
      />
      {/* Initialize Google Analytics */}
      <Script 
        id="google-analytics" 
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${ga_id}');
          `,
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
