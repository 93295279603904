import Blogger from "@/assets/svgs/platforms/BloggerDark.png";
import Facebook from "@/assets/svgs/platforms/FacebookLight.svg";
import GBP from "@/assets/svgs/platforms/GBPLight.svg";
import Instagram from "@/assets/svgs/platforms/InstagramLight.svg";
import LinkedIn from "@/assets/svgs/platforms/LinkedInLight.svg";
import Pinterest from "@/assets/svgs/platforms/PinterestLight.svg";
import Reddit from "@/assets/svgs/platforms/RedditLight.svg";
import RSSFeed from "@/assets/svgs/platforms/RSSFeedLight.svg";
import Telegram from "@/assets/svgs/platforms/TelegramLight.svg";
import TikTok from "@/assets/svgs/platforms/TikTokDark.svg";
import Threads from "@/assets/svgs/platforms/ThreadsDark.png";
import Twitter from "@/assets/svgs/platforms/TwitterLight.svg";
import WordPress from "@/assets/svgs/platforms/WordPressDark.svg";
import YouTube from "@/assets/svgs/platforms/YouTubeLight.svg";
import Bluesky from "@/assets/svgs/platforms/BlueskyLight.svg";
import SparkleBlue from "@/assets/svgs/SparkleBlue.svg";
import OpenAILogo from "@/assets/svgs/OpenAILogo.svg";
import SiteMetaData from "../SiteMetaData";


export const navData = [  
  {
    _id: 100,
    enableOnMobile: true,
    title: "Publisher",
    href: "/analytics",
    subLinks: [      
      {
        _id: 101,
        title: 'Analytics',
        href: "/analytics",
        icon: SparkleBlue,
        description: 'Measure and monitor your social media growth.'
      },
      {
        _id: 102,
        title: 'Approval workflow',
        href: "/approval-workflow",
        icon: SparkleBlue,
        description: 'Collaboration for teams and clients.'
      },
      {
        _id: 103,
        title: 'Audience groups',
        href: "/audience-groups",
        icon: SparkleBlue,
        description: 'Get organized.'
      },
      {
        _id: 104,
        title: 'Bulk post',
        href: "/bulk-post",
        icon: SparkleBlue,
        description: 'Save time and accelerate growth!'
      },
    //  {
     //   _id: 105,
    //    title: 'Crosspost X to Bluesky',
    //    href: "/x-to-bluesky",
    //    icon: SparkleBlue,
    //    description: 'Crosspost your content from X to Bluesky.'
    //  },
      {
        _id: 106,
        title: 'Facebook carousel',
        href: "/facebook-carousel",
        icon: SparkleBlue,
        description: 'Facebook campaigns with carousels'
      },
      {
        _id: 107,
        title: 'Instagram collaborator',
        href: "/instagram",
        icon: SparkleBlue,
        description: 'Invite Instagram collaborators'
      },         
      {
        _id: 108,
        title: 'Integrations',
        href: "/integrations",
        icon: SparkleBlue,
        description: 'Best tools to boost your publishing campaigns.'
      },      
      {
        _id: 109,
        title: 'Link preview',
        href: "/link-preview",
        icon: SparkleBlue,
        description: 'Preview your links.'
      },
      {
        _id: 110,
        title: 'Media library',
        href: "/media-library",
        icon: SparkleBlue,
        description: 'Design and empower creativity.'
      },      
      {
        _id: 111,
        title: 'Postly cloud',
        href: "/postly-cloud",
        icon: SparkleBlue,
        description: 'Get your media asset organized.'
      },
      {
        _id: 112,
        title: 'Post preview',
        href: "/post-preview",
        icon: SparkleBlue,
        description: 'Preview your posts.'
      },
      {
        _id: 113,
        title: 'Recurring post',
        href: "/recurring-post",
        icon: SparkleBlue,
        description: 'Best for evergreen content.'
      },
      {
        _id: 114,
        title: 'Republish posts',
        href: "/republish-posts",
        icon: SparkleBlue,
        description: 'Republish your published posts.'
      },
      {
        _id: 115,
        title: 'Social inbox',
        href: "/social-inbox",
        icon: SparkleBlue,
        description: 'Simplifying your social media communication and collaboration efforts!'
      },
      {
        _id: 116,
        title: 'White-label',
        href: "/white-label",
        icon: SparkleBlue,
        description: 'Make Postly your own!'
      },      
      {
        _id: 117,
        title: 'Mobile app',
        href: "/mobile-app",
        icon: SparkleBlue,
        description: 'Share posts across Facebook groups, personal profiles and other channels'
      },

    ],
  },  
  
  {
    _id: 50,
    enableOnMobile: true,
    title: "Platforms",
    href: "/blogger",
    subLinks: [
      {
        id: 1,
        title: 'Blogger',
        href: "/blogger",
        icon: Blogger,
        description: 'Schedule articles on Blogger'
      },
      {
        id: 2,
        title: 'Bluesky',
        href: "/bluesky",
        icon: Bluesky,
        description: 'Schedule articles on Bluesky'
      },
      {
        _id: 3,
        title: 'Facebook',
        href: "/facebook",
        icon: Facebook,
        description: 'Schedule posts on Facebook.'
      },
      {
        _id: 4,
        title: 'Google Business Profile',
        href: "/google-business-profile",
        icon: GBP,
        description: 'Schedule posts on Google Business Profiles.'
      },
      {
        _id: 5,
        title: 'Instagram',
        href: "/instagram",
        icon: Instagram,
        description: 'Schedule posts on Instagram.'
      },
      {
        _id: 6,
        title: 'LinkedIn',
        href: "/linkedin",
        icon: LinkedIn,
        description: 'Schedule posts on LinkedIn.'
      },
      {
        _id: 7,
        title: 'Pinterest',
        href: "/pinterest",
        icon: Pinterest,
        description: 'Schedule posts on Pinterest.'
      },
      {
        _id: 8,
        title: 'Reddit',
        href: "/reddit",
        icon: Reddit,
        description: 'Schedule posts on Reddit.'
      },
      {
        _id: 9,
        title: 'RSS Feed',
        href: "/rss-feed",
        icon: RSSFeed,
        description: 'Schedule RSS Feed updates and publish everywhere.'
      },
      {
        _id: 10,
        title: 'Telegram',
        href: "/telegram",
        icon: Telegram,
        description: 'Schedule posts on Telegram.'
      },
      {
        _id: 11,
        title: 'TikTok',
        href: "/tiktok",
        icon: TikTok,
        description: 'Schedule posts on TikTok.'
      },
      {
        _id: 12,
        title: 'Threads',
        href: "/threads",
        icon: Threads,
        description: 'Schedule posts on Threads.'
      },
      {
        _id: 13,
        title: 'Twitter',
        href: "/twitter",
        icon: Twitter,
        description: 'Schedule posts on Twitter.'
      },
      {
        _id: 14,
        title: 'WordPress',
        href: "/wordpress",
        icon: WordPress,
        description: 'Schedule blog posts on WordPress.'
      },
      {
        _id: 15,
        title: 'YouTube',
        href: "/youtube",
        icon: YouTube,
        description: 'Schedule posts on YouTube.'
      },
    ],
  },
  
  {
    _id: 200,
    enableOnMobile: true,
    title: "Resources",
    href: "/content-format",
    subLinks: [      
      {
        _id: 201,
        title: 'Content format',
        href: "/content-format",
        icon: SparkleBlue,
        description: 'Content format guide to ensure good experience.'
      },
      {
        _id: 202,
        title: 'Demo videos',
        href: "/demo-videos",
        icon: SparkleBlue,
        description: 'The help you need to get started.'
      },
      {
        _id: 203,
        title: 'FAQ',
        href: "/faq",
        icon: SparkleBlue,
        description: 'Frequently asked questions.',
        target: "_blank",
        rel: "noopener noreferrer"
      },
      {
        _id: 204,
        title: 'Help center',
        href: "/help-center",
        icon: SparkleBlue,
        description: 'The help you need to get started.'
      },
      {
        _id: 205,
        title: 'How it works',
        href: "/how-it-works",
        icon: SparkleBlue,
        description: 'The help you need to get started.'
      },      
      {
        _id: 206,
        title: 'Roadmap & changelog',
        href: SiteMetaData.productUpdate,
        icon: SparkleBlue,
        description: 'The all-in-one social media tool.',
        target: "_blank",
        rel: "noopener noreferrer"
      },
      {
        _id: 207,
        title: 'Approval workflow',
        href: "/approval-workflow",
        icon: SparkleBlue,
        description: 'Review and approve Posts',
        target: "_blank",
        rel: "noopener noreferrer"
      },      
      {
        _id: 208,
        title: 'Workspaces',
        href: "/workspace",
        icon: SparkleBlue,
        description: 'Organize, schedule and monitor.'
      },      
      {
        _id: 209,
        title: 'Blog',
        href: "https://blog.postly.ai",
        icon: SparkleBlue,
        description: 'Social media marketing strategy tips.'
      },
      {
        _id: 210,
        title: 'Give us feedback',
        href: SiteMetaData.feedbackForm,
        icon: SparkleBlue,
        description: 'We value your feedback.',
        target: "_blank",
        rel: "noopener noreferrer"
      },
      {
        _id: 210,
        title: 'Free tools',
        href: "/free-tools",
        icon: SparkleBlue,
        description: 'Explore free tools for social media success',
      },
    ],
  },  

  {
    _id: 400,
    enableOnMobile: true,
    title: "Pricing",
    href: "/pricing",
    description: 'Simple and affordable.'
  },
];
