import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const NavSubItem = ({ item, closeMenu }) => {
  return (
    <Link
      href={item?.href}
      className={`flex justify-start ${item?.description ? 'items-start' : 'items-center'} gap-2 cursor-pointer hover:bg-blue-100 p-2 rounded-md`}
      onClick={closeMenu} // Trigger closeMenu when clicked
    >
      <Image
        src={item?.icon}
        width={24}
        height={24}
        className='mr-2 mt-1'
        alt=''
      />
      <div className='flex flex-col w-56'>
        <h1 className='text-sm font-bold'>{item?.title}</h1>
        <p className='text-sm font-thin'>{item?.description}</p>
      </div>
    </Link>
  );
};

export default NavSubItem;
